$color-change-red: #CF213A;
$color-change-red-light: #D23B4A;

$color-change-tangerine: #FA9B25;
$color-change-tangerine-light: #FFAF50;
$color-change-orange: #E07500;

$color-change-teal: #45947E;
$color-change-teal-light: #58A08F;

$color-change-purple: #574584;
$color-change-purple-light: #5B5087;

$color-change-pitch: #252525;
$color-change-charcoal: #353535;
$color-change-steel: #474747;
$color-change-grey: #606060;

$color-change-white: #F4F4F4;

$colors: $color-change-tangerine, $color-change-red, $color-change-teal, $color-change-purple, $color-change-steel, $color-change-tangerine-light, $color-change-red-light, $color-change-teal-light, $color-change-purple-light, $color-change-grey, $color-change-charcoal, $color-change-pitch, $color-change-white;
$text-colors: $color-change-orange, $color-change-red, $color-change-teal, $color-change-purple, $color-change-steel, $color-change-orange, $color-change-red, $color-change-teal, $color-change-purple, $color-change-grey, $color-change-charcoal, $color-change-pitch, $color-change-pitch;