.section-general {
	&:not(.site-footer) {
		.section-block {
			padding: 5rem 0;

			&.-no-padding {
				padding: 0;
			}

			&.-no-padding-top {
				padding-top: 0;
			}

			&.-no-padding-bottom {
				padding-bottom: 0;
			}

			&.-full-height {
				display: flex;
				width: 100%;
				height: 100%;
				justify-content: center;
				align-items: center;
			}
		}
	}

	.section-block {
		.block-inner {
			max-width: 87.5rem;
			padding: 0 2rem;
			margin: 0 auto;

			&.-full-width {
				max-width: none;
			}

			& > * {
				&:not(:first-child):not(.link-group) {
					margin-top: 3.75rem;
				}
			}

			&:not(:empty) + .block-inner:not(.-no-margin) {
				margin-top: 3.75rem;
			}
		}
	}

	.wysiwyg {
		& > * {
			&:not(:first-child) {
				margin-top: 3.75rem;
			}
		}

		h1, h2, h3, h4, h5, h6 {
			& + p {
				margin-top: 1rem !important;
			}
		}

		p {
			& + p {
				margin-top: 1rem !important;
			}
		}

		.body-small {
			font-size: 1.75rem;

			@media screen and (max-width: $bp-desktop) {
				font-size: 2rem;
			}
		}

		ul {
			padding-left: 20px;

			list-style-type: disc;
		}

		.text-center {
			text-align: center;
		}

		.text-right {
			text-align: right;
		}
	}

	@media screen and (max-width: $bp-tablet) {
		&:not(.site-footer) {
			.section-block {
				.block-inner {
					padding: 0 5rem;
				}
			}
		}
	}
}