.home-header {
	height: 100vh;

	.header-background {
		position: absolute;
		top: 0;
		left: 0;

		height: 100%;
		width: 100%;

		background: {
			color: #ccc;
			size: cover; 
		}
	}

	.header-banner {

	}
}