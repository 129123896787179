.site-nav {
	position: fixed;
	top: 0;
	left: 0;
	z-index: 10;

	height: 15rem;
	width: 100%;
	overflow: hidden;

	background: white;
	box-shadow: 0 0.25rem 1.5rem 0.25rem rgba(black, 0.1);

	.inner {
		max-width: none;
		height: 100%;
		display: flex;
		justify-content: space-between;
	}

	.nav-left {
		position: relative;

		flex: 0 0 auto;
		width: 45rem;

		.site-logo {
			position: absolute;
			top: 50%;
			transform: translate(-2rem, -50%);

			width: 100%;

			svg {
				width: 100%;
			}
		}
	}

	.btn-toggle-nav {
		display: none;
	}

	.nav-right {
		height: 100%;
		padding-right: 10rem;

		.nav-list {
			display: flex;
			align-items: center;

			.nav-item {
				font-size: 2.5rem;
				@include font-primary-bold;

				&:not(:first-child) {
					margin-left: 2rem;
				}

				&.-active {
					a {
						&::before {
							transform: scale(1);

							background: $color-change-white;
							visibility: visible;
							opacity: 1;
						}
					}
				}

				&:not(.-active) {
					a {
						&:hover, &:focus {
							&::before {
								transform: scale(1);

								visibility: visible;
								opacity: 1;
							}
						}
					}
				}

				a {
					position: relative;
					z-index: 1;

					padding: 2.5rem 3rem;

					color: inherit;

					&::before {
						content: '';

						position: absolute;
						top: 0;
						left: 0;
						z-index: -1;
						transform: scale(0);

						display: block;
						width: 100%;
						height: 100%;

						border-radius: 0.75rem;
						background: $color-change-tangerine;
						visibility: hidden;
						opacity: 0;

						transition: all $transition;
					}
				}
			}
		}
	}

	@media screen and (min-width: #{$bp-tablet + 1px}) {
		.nav-list {
			height: 100% !important;
		}
	}

	@media screen and (max-width: $bp-tablet) {
		position: fixed;

		height: auto;
		overflow: visible;

		box-shadow: none;

		body:not(.-nav-active) & {
			.nav-right {
				.nav-list {
					height: 0 !important;
				}
			}
		}

		.inner {
			display: block;
		}

		.nav-left {
			position: relative;
			z-index: 5;

			display: flex;
			justify-content: flex-start;
			align-items: center;
			width: 100%;
			height: 10rem;
			padding-left: 1.5rem;
			overflow: hidden;

			box-shadow: 0 1rem 1.5rem rgba(black, 0.1);
			background: white;

			.site-logo {
				// position: relative;
				// top: auto;
				transform: translate(-4rem, -50%);

				width: 32.5rem;
			}
		}

		.nav-right {
			position: relative;

			display: block;
			width: 100%;
			height: auto;
			padding: 0;

			.btn-toggle-nav {
				position: absolute;
				top: -7.5rem;
				right: 3rem;
				z-index: 10;

				display: block;
				width: 5rem;
				height: 5rem;
				flex: 0 0 auto;

				background: {
					image: url('../img/icon-hamburger.svg');
					size: 100%;
					position: center;
					repeat: no-repeat;
				}
			}

			.nav-list {
				display: block;
				width: 100%;
				overflow: hidden;

				background: $color-change-pitch;
				color: #DDD;

				transition: all $transition;

				.nav-item {
					margin-left: 0 !important;
					height: 8rem;
					display: flex;
					width: 100%;

					&:first-child {
						margin-top: 3rem;
					}

					&:last-child {
						margin-bottom: 3rem;
					}

					&.-active {
						a {
							&::before {
								background: $color-change-charcoal;
								color: white;
								border-radius: 0;
							}
						}
					}

					a {
						display: flex;
						justify-content: center;
						align-items: center;
						width: 100%;
						height: 100%;

						font-size: 3.75rem;
					}
				}
			}
		}
	}
}