$rem-size: 8px;
$font-size-small: 10px;

@mixin font-size() {
	@include font-size-other($font-size)
}

@mixin font-size-small() {
	@include font-size-other($font-size-small)
}

@mixin font-size-other($fs) {
	font-size: $fs;

	// @for $i from 1 through 4 {
	// 	@media all and (min-width: #{1200 + $i * 180)}px) {
	// 		font-size: ($fs + $i * 1.5);
	// 	}
	// }
}

html {
	font-size: $rem-size;

	@media screen and (max-width: $bp-desktop) {
		font-size: ($rem-size - 2);
	}

	// @for $i from 1 through 4 {
	// 	@media all and (min-width: #{1200 + $i * 180)}px) {
	// 		font-size: ($font-size / 3 + $i / 2);
	// 	}
	// }
}

body {
	@include font-primary-regular();
	font-size: 2.25rem;
}

// * {
// 	line-height: 1.2em;

// 	&::before, &::after {
// 		line-height: 1em;
// 	}
// }

p, h1, h2, h3, h4, h5, h6 {
	margin: 0;
	padding: 0;
}

h1, h2, h3, h4 {
	@include font-primary-bold;
}

h1 {
	font-size: 8rem;
}

h2 {
	font-size: 6.5rem;
}

h3 {
	font-size: 5rem;
	line-height: 1.2em;
}

h4 {
	font-size: 3rem;
	line-height: 1.4em;
}

p, .body, ul {
	@include font-primary-regular;
	line-height: 1.75em;
}

a {
	text-decoration: inherit;
	@include font-primary-bold;
}

.link {
	@include font-primary-bold;
	text-decoration: underline;
}

.-strike {
	text-decoration: line-through; 
}
