input, select {
	height: 6.25rem;
	padding: 2rem 2.5rem;
	flex: 1 1 auto;

	border: 0;
	outline: 0;
	border-radius: 1.5rem;
	background: white;
	color: black;

	vertical-align: top;
}

select {
	padding: 0 2.5rem;

	appearance: none;

	@include font-primary-regular;
	font-size: 2.25rem;
}

form {
	.alert {
		padding: 2.5rem 3rem;

		border-radius: 1.5rem;

		&.alert-error {
			background: #AA0928;
			color: white;
		}
	}

	.form-group {
		display: flex;
		flex-wrap: nowrap;
		justify-content: space-between;

		&:not(:first-child) {
			margin-top: 2rem;
		}

		.form-field {
			flex: 0 0 auto;

			&:first-child:not(:last-child) {
				padding-right: 1rem;
			}

			&:nth-child(2) {
				padding-left: 1rem;
			}

			&:not(:first-child):not(:nth-child(2)) {
				padding-left: 2rem;
			}
		}
	}

	.form-field {
		&.-hidden {
			display: none;
		}

		input, select {
			width: 100%;
		}

		label {
			display: block;

			@include font-primary-bold;
			
			& + * {
				margin-top: 1rem;
			}
		}
	}

	.radio-field {
		&:not(:first-child) {
			margin-top: 7.5rem;
		}

		& + .form-group, & + .form-field {
			margin-top: 7.5rem;
		}

		& > label {
			font-size: 3rem;
			@include font-primary-bold;

			& + .radio-button-group {
				margin-top: 1.5rem;
			}
		}
	}

	.select-field {
		label {
			position: relative;

			&::after {
				content: '';

				position: absolute;
				top: 7.125rem;
				right: 2.5rem;
				transform: translateY(-50%);

				display: block;
				height: 2rem;
				width: 2rem;

				background: {
					image: url('../img/svg/svg-symbols/icon-dropdown.svg');
					size: 100%;
				}
			}
		}
	}

	.radio-button-group {
		display: flex;
		overflow: hidden;

		box-shadow: 0 0 0 0.5rem white inset;
		border-radius: 1.5rem;

		.input-group {
			position: relative;

			flex: 1 1 auto;

			&:not(:first-child) {
				label {
					box-shadow: -0.25rem 0 0 white, 0.25rem 0 0 white inset;
				}
			}

			input[type='radio'] {
				position: absolute;
				z-index: -1;

				opacity: 0;

				&:focus {
					& + label {
						background: rgba(white, 0.2);
						color: white;
					}
				}

				&:checked {
					& + label {
						background: white;
						color: $color-change-charcoal;
					}
				}
			}

			label {
				display: flex;
				justify-content: center;
				align-items: center;
				width: 100%;
				height: 6.25rem;

				color: white;

				font-size: 2.25rem;
				@include font-primary-bold;

				transition: all $transition;

				&:hover {
					background: rgba(white, 0.2);
					color: white;

					cursor: pointer;
				}
			}
		}
	}

	.button-group {
		display: flex;
		margin-top: 3.75rem;
	}

	.submit-group {
		justify-content: flex-end;
	}

	.btn-submit {
		background: $color-change-charcoal;
		color: white;

		@include font-primary-bold;

		&:hover, &:focus {
			transform: translateY(-0.25rem);

			box-shadow: 0 0.5rem 1rem rgba(black, 0.3);
		}
	}

	@media screen and (max-width: $bp-tablet) {
		.radio-button-group {
			display: block;

			.input-group {
				&:not(:first-child) {
					box-shadow: 0 -0.25rem 0 white, 0 0.25rem 0 white inset;
				}
			}
		}

		.form-group {
			display: block;

			.form-field {
				width: 100% !important;
				padding: 0 !important;

				&:not(:first-child) {
					margin-top: 2rem;
				}
			}
		}
	}
}