.section-home {
	min-height: 100%;
	padding-top: 15rem;

	background: $color-change-tangerine;
	background: {
		image: url('../img/bg.svg');
		size: cover;
		position: center;
	}

	text-align: center;

	h1 {

	}

	.pitch-video {
		img {
			border-radius: 1.5rem;
		}
	}

	.info {
		margin-top: 3.75rem;

		a {
			color: inherit;

			text-decoration: underline;
		}
	}
}