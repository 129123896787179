body {
	color: $color-change-charcoal;
}

.page-container {
	// display: flex;
	min-height: 100vh;
	width: 100%;

	& > * {
		width: 100%;
	}
}

.section {
	position: relative;

	.inner {
		position: relative;

		width: 100%;
		@include max-width;
		margin: 0 auto;
		padding: 0 2.5rem;
	}

	@media screen and (max-width: $bp-tablet) {
		.inner {
			padding: 0 3.75rem;
		}
	}
}

.list {
	padding: 0;
	margin: 0;

	list-style-type: none;
}

@for $i from 1 through 13 {	
	[data-color='#{$i}'] {
		&:not(.-color-reverse) {
			color: white;

			&[data-color='13'] {
				color: $color-change-grey;
			}
			
			&.section-block, &.btn-link, &.btn-submit, &.site-footer {
				background: nth($colors, $i);
			}

			.radio-button-group {
				input[type='radio'] {
					&:checked {
						& + label {
							color: nth($text-colors, $i);
						}
					}
				}
			}

			a {
				&:focus, &:hover {
					color: $color-change-charcoal;
				}
			}
		}

		&.-color-reverse {
			h2, h3, h4 {
				color: nth($text-colors, $i);
			}

			blockquote {
				border-color: nth($colors, $i) !important;
			}

			a {
				&:focus, &:hover {
					color: nth($text-colors, $i);
				}
			}	

			&.info-card {
				color: nth($text-colors, $i);
			}
		}
	}

	@media screen and (max-width: $bp-tablet) {
		.page-container {
			padding-top: 10rem;
		}
	}
}


[data-color='5'], [data-color='10'], [data-color='11'], [data-color='12'] {
	&.-color-reverse, &:not(.-color-reverse) {
		a {
			&:focus, &:hover {
				color: nth($colors, 1);
			}
		}
	}
}