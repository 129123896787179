@import url('https://fonts.googleapis.com/css?family=Open+Sans:400,700');

$font-primary: 'Open Sans', 'Arial', sans-serif;

@mixin font-primary-regular() {
	font-family: $font-primary;
	font-weight: 400;
}

@mixin font-primary-bold() {
	font-family: $font-primary;
	font-weight: 700;
}
