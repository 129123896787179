.card {
	position: relative;

	padding: 0 3.75rem;

	border-radius: 1rem;
	background: white;

	h3 {
		font-size: 4rem;
		// text-transform: uppercase;
		letter-spacing: -0.01em;
		line-height: 1.2em;
	}

	// .content {
	// 	h3 {
	// 		font-size: 5rem;
	// 		text-transform: uppercase;
	// 		letter-spacing: 0.1em;
	// 	}
	// }
}

.info-card {
	display: flex;
	width: 100%;
	padding: 0;
	overflow: hidden;

	color: $color-change-charcoal;

	&.-image-right {
		flex-direction: row-reverse;
	}

	&:not(:first-child) {
		margin-top: 2.25rem;
	}

	.card-image {
		position: relative;

		width: 40%;
		flex: 0 0 auto;

		.image {
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);

			width: 100%;
		}
	}

	.card-content {
		width: 60%;
		flex: 0 0 auto;
		padding: 5rem;

		font-size: 2rem;

		.card-body {
			& > * {
				&:not(:first-child) {
					margin-top: 2rem;
				}
			}

			h3 {
				font-size: 5rem;
				letter-spacing: 0;
				text-transform: none;
				line-height: 1.2em;
			}
		}
	}

	@media screen and (max-width: $bp-tablet) {
		display: block;

		&.-reverse {
			background: $color-change-charcoal;
			color: white;
		}

		& > * {
			width: 100% !important;
		}

		.card-image {
			overflow: hidden;
			padding-bottom: 65%;
		}
	}
}