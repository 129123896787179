.content {
	blockquote {
		line-height: 1.7em;

		&:not(.email-snippet) {
			padding-left: 3rem;

			border-left: 0.5rem solid currentColor;
		}

		&.email-snippet {
			position: relative;
			z-index: 1;

			margin-top: 4rem !important;

			&::before {
				content: '';

				position: absolute;
				left: 50%;
				top: 50%;
				transform: translate(-50%, -50%);
				z-index: -1;

				display: block;
				width: calc(100% + 4rem);
				height: calc(100% + 4rem);

				background: black;
				opacity: 0.05;
			}
		}
	}

	a {
		color: inherit;

		text-decoration: underline;

		transition: all 0.3s;
	}

	.link-group {
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
		margin-top: -2rem;

		&:not(:first-child) {
			margin-top: 1rem;

			& + * {
				margin-top: 3rem;
			}
		}

		.btn-link {
			display: flex;
			align-items: center;
			padding: 2.5rem 3rem;
			max-width: 30rem;
			flex: 0 0 auto;
			margin: 2rem 1rem 0;

			border-radius: 0.75rem;

			text-decoration: none;
			text-align: center;
			line-height: 1.4em;

			transition: all $transition;

			* {
				line-height: inherit;
			}

			&:hover, &:focus {
				color: white;

				transform: translateY(-0.25rem);

				box-shadow: 0 0.5rem 1rem rgba(black, 0.3);
			}

			&.-no-padding {
				transform: translateZ(0);

				padding: 0;

				&:not(:first-child) {
					margin-left: 4rem;
				}

				&:hover, &:focus {
					transform: translate3d(0, -0.25rem, 0);

					box-shadow: none;
					filter: drop-shadow(0 0.5rem 0.25rem rgba(black, 0.3));
				}

				img.-mobile {
					display: none;
				}
			}

			&.card {
				* {
					font-weight: inherit;
				}
			}
		}
	}

	.spacer {
		margin-top: 10rem;
	}

	.video-embed {
		position: relative;

		padding-bottom: 56.25%;

		iframe {
			position: absolute;
			top: 0;
			left: 0;

			width: 100%;
			height: 100%;
		}
	}

	@media screen and (max-width: $bp-tablet) {
		.link-group {
			.btn-link {
				&.-no-padding {
					img.-mobile {
						display: inline-block;
					}

					img.-desktop {
						display: none;
					}
				}
			}
		}
	}
}