.btn {
	padding: 0;
	margin: 0;

	background: transparent;
	border: 0;
	outline: 0;
	color: inherit;

	text-decoration: none;

	transition: all $transition;
}

.btn-default {
	height: 6.25rem;
	padding: 0 3rem;

	border-radius: 1.5rem;
}