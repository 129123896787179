.section-change {
	width: 100%;
	padding: 14rem 0;
	min-height: 58rem;
	box-sizing: content-box;
	overflow: hidden;

	transition: height $transition-slide;

	.inner {
		position: relative;
		z-index: 1;

		min-height: calc(100vh - 15rem);
		max-width: none;
		height: 100%;
		box-sizing: border-box;
	}

	.change-background {
		background: $color-change-teal;
	}

	.card {
		color: $color-change-charcoal;
	}

	.info-card {
		color: $color-change-charcoal;
	}

	.breadcrumbs {
		.list-item {
			&::before, &::after {
				box-shadow: -2px 0 0 0 rgba($color-change-teal, 0.5) inset;
			}
		}
	}

	// @for $i from 1 through 6 {
	// 	&[data-level='#{$i}'] {
	// 		.change-background {
	// 			&::before {
	// 				transform: translate3d(-#{$i * 30vw}, 0, 0.1px);
	// 			}
	// 		}
	// 	}
	// }


	.card {
		color: nth($text-colors, 5);
	}
	
	@for $i from 1 through 5 {
		&[data-color="#{ $i }"] {
			.change-background {
				background: nth($colors, $i);
			}

			.breadcrumbs {
				.list-item {
					&::before, &::after {
						box-shadow: -2px 0 0 0 rgba(nth($colors, $i), 0.5) inset;
					}
				}
			}
		}
	}

	&[data-color="5"] {
		.card {
			color: nth($text-colors, 1);
		}

		.info-card {
			color: nth($text-colors, 5);

			h3 {
				color: nth($text-colors, 1);
			}
		}
	}

	&[data-level="1"] {

		// &.-fixed {
		// 	position: absolute;
		// 	top: -24rem;

		// 	.btn-back {
		// 		background: transparent;
		// 	}
		// }

		// .btn-back {
		// 	pointer-events: none;

		// 	* {
		// 		opacity: 0.3;
		// 	}
		// }
	}

	.change-background {
		position: fixed;
		top: 0;
		left: 0;

		height: 100%;
		width: 100%;

		transition: 0.1s;
	}

	.slide-container {
		position: relative;
		perspective: 1000px;

		display: flex;
		width: 100%;
		height: 100%;

		transition: all $transition-slide;

		@for $i from 1 through 8 {
			&[data-choice='#{$i}'] {
				// @for $j from 1 through 6 {
				// 	&[data-total='#{$j}'] {
						.-past {
							.choice-list {
								.list-item {
									&:nth-child(#{$i}) {
										opacity: 1;
									}
								}
							}
						} 

						.-active {
							.slide-line {
								// transform: translateX(-100%) translateY(-#{($j * 44rem / 2)}) translateY(#{22rem + ($i - 1) * 44rem});
								height: #{20 * ($i - 1)}rem;
							}
						}

						& + .change-info {
							.slide-line {
								// transform: translateX(-100%) translateY(-#{($j * 44rem / 2)}) translateY(#{22rem + ($i - 1) * 44rem});
								height: #{20 * ($i - 1)}rem;
							}
						}
				// 	}
				// }
			}
		}

		&[data-choice='1'] {
			.-active {
				.slide-line {
					height: 0.75rem;
				}
			}

			& + .change-info {
				.slide-line {
					height: 0.75rem;
				}
			}
		}

		&[data-total='0'], &[data-choice='0'] {
			.-active {
				.slide-line {
					width: calc(100vw - 90rem);
				}
			}
		}

		&[data-total='1'][data-choice='0'], &[data-total='0'][data-choice='1'] {
			.-active, & + .change-info {
				.slide-line {
					top: 50%;
					transform: translateY(-50%) translateX(-100%);
				}
			}
		}
	}

	.change-slide {
		position: relative;

		flex: 0 0 auto;
		width: 50%;
		height: 100%;

		&:first-child {
			margin-left: 25%;
		}

		.choice-list {
			color: $color-change-orange;
		}
	}

	.slide-group {
		position: absolute;
		left: 50%;
		transform: translate(-50%, 0);
		perspective: 1000;

		display: flex;
		justify-content: flex-end;
		// height: 100%;
		width: 90rem;
		// overflow: hidden;

		opacity: 0;
		visibility: hidden;

		transition: all $transition-slide;

		&:not(.-active):not(.-past) {
			.choice-list {
				transform: rotateY(-90deg) scale(0.625);
			}
		}

		&:not(.-active) {
			.slide-info {
				opacity: 0;
			}
		}

		&.-active, &.-past {
			opacity: 1;
			visibility: visible;

			&[data-total='2'] {
				&::before {
					transform: translateY(0) scaleY(1);
				}
			}

			&::before {
				transform: translateY(-50%) scaleY(1);

				width: 3rem;
			}

			.slide-line {
				&::before {
					transform: scaleX(1);
				}
			}

			.choice-list {
				transform: rotateY(0);
			}
		}

		&.-active {
			overflow: visible;
			height: auto;

			.card {
				&.-visited {
					color: rgba(white, 0.8);
					background: rgba(white, 0.1);
				}

				&:hover, &:focus {
					transform: translateY(-0.25rem);

					// animation: shake $transition, shake 0.1s 0.2s;
					box-shadow: 0 0.5rem 1rem rgba(black, 0.3);
				}
			}
		}

		&.-past {
			.choice-list {
				transform: translateX(-80%);

				&:hover {
					cursor: w-resize;
				}

				.list-item {
					opacity: 0.5;
				}

				.card {
					pointer-events: none;
				}
			}
		}

		&[data-total='2'] {
			&::before {
				top: -3rem;
				transform: translateY(0) scaleY(0);
			}
		}

		&::before {
			content: '';

			position: absolute;
			top: 50%;
			left: -3rem;
			z-index: -1;
			transform: translateY(-50%) scaleY(0);
			transform-origin: right 9rem;

			// width: 12rem;
			height: calc(100% + 6rem);
			min-height: 60rem;
			margin-bottom: 4rem;
			width: 0;

			border: {
				top-left-radius: 3rem;
				bottom-left-radius: 3rem;
				left: 0.75rem solid white;
				top: 0.75rem solid white;
				bottom: 0.75rem solid white;
			}

			transition: transform 0.4s 0.5s,
				width 0.2s 0.75s;
		}

		.slide-line {
			position: absolute;
			top: 9rem;
			left: -3rem;
			transform: translateX(-100%);

			display: block;
			width: calc(50vw - 45rem * 1.2 - 3rem);
			height: 0.75rem;

			&::before, &::after {
				content: '';

				position: absolute;
				// transform: scaleX(0);
				// transform-origin: left center;

				display: block;
				width: calc(50% + 0.375rem);
				height: 50%;

				transition: transform $transition-slide;
				transition-duration: 0.8s;
			}

			&::before {
				left: 0;
				bottom: 0;

				border-bottom-right-radius: 3rem;
				border: {
					bottom: 0.75rem solid white;
					right: 0.75rem solid white;
				}
			}

			&::after {
				top: 0;
				right: 0;

				border-top-left-radius: 3rem;
				border: {
					top: 0.75rem solid white;
					left: 0.75rem solid white;
				}
			}
		}

		.slide-info {
			position: absolute;
			left: -1.5rem;
			top: 0;

			width: calc(100% - 45rem);
			padding: 0 4rem;

			color: white;
			text-shadow: 0 0.25rem 1rem rgba(black, 0.2);

			transition: opacity $transition-slide;

			.slide-title {
				.text {
					width: 100%;

					line-height: 1em;
				}
			}

			.slide-body {
				margin-top: 7rem;

				font-size: 2.5rem;
				line-height: 1.5em;

				* {
					line-height: inherit;
				}
			}
		}

		.choice-list {
			position: relative;
			transform-origin: right calc((100vh - 15rem) / 2);

			width: 45rem;

			transition: all $transition-slide;

			.list-item {
				width: 100%;
				height: 18rem;

				&:not(:first-child) {
					margin-top: 2rem;
				}
			}

			.card {
				display: flex;
				justify-content: center;
				align-items: center;
				width: 100%;
				height: 100%;

				transition: all $transition, color $transition-slide;

				.card-title {
					text-align: center;

					* {
						line-height: inherit;
						font-size: inherit;
						font-weight: inherit;
						letter-spacing: inherit;
					}
				}
			}
		}
	}

	&.-info {
		.change-info {
			transform: translateX(-50%);

			visibility: visible;
			opacity: 1;
			overflow: visible;

			.slide-line {
				&::before {
					transform: scaleX(1);
				}
			}

			.card-group.-active {
				transform: translateX(-50%);

				visibility: visible;
				opacity: 1;

				.card-list {
					transform: rotateY(0) scale(1);

					&::before {
						transform: translateY(-50%) scaleY(1);
						width: 3rem;
					}
				}

				// &::before {
				// 	transform: translateY(-50%) scaleX(1);
				// 	width: 6rem;
				// }
			}
		}
	}

	.change-info {
		position: absolute;
		top: 0;
		left: 50%;
		z-index: 5;
		transform: translateX(50%);
		perspective: 1000;

		display: flex;
		justify-content: center;
		width: 50%;
		height: 100%;
		overflow: hidden;

		visibility: hidden;
		opacity: 0;

		pointer-events: none;

		transition: all $transition-slide;

		.slide-line {
			position: absolute;
			top: 13.5rem;
			left: -3rem;
			transform: translateX(-100%);

			display: block;
			width: calc(50vw - 45rem * 0.2 - 43.75rem - 3rem);
			height: 0.75rem;

			&::before, &::after {
				content: '';

				position: absolute;
				// transform: scaleX(0);
				// transform-origin: left center;

				display: block;
				width: calc(50% + 0.375rem);
				height: 50%;

				transition: transform $transition-slide;
				transition-duration: 0.8s;
			}

			&::before {
				left: 0;
				bottom: 0;

				border-bottom-right-radius: 3rem;
				border: {
					bottom: 0.75rem solid white;
					right: 0.75rem solid white;
				}
			}

			&::after {
				top: 0;
				right: 0;

				border-top-left-radius: 3rem;
				border: {
					top: 0.75rem solid white;
					left: 0.75rem solid white;
				}
			}
		}

		.card-group {
			position: absolute;
			top: 0;
			left: 50%;
			perspective: 1000;

			width: 87.5rem;
			flex: 0 0 auto;
			visibility: hidden;
			opacity: 0;
			margin-top: -4rem;

			pointer-events: auto;

			transition: all $transition-slide;

			&.-active {
				display: block;
			}

			// &::before {
			// 	content: '';

			// 	position: absolute;
			// 	top: 50%;
			// 	left: -6rem;
			// 	z-index: -1;
			// 	transform: translateY(-50%) scaleY(0);

			// 	// width: 12rem;
			// 	height: calc(100% + 12rem);
			// 	min-height: 140rem;
			// 	width: 0;

			// 	border: {
			// 		top-left-radius: 6rem;
			// 		bottom-left-radius: 6rem;
			// 		left: 1.5rem solid white;
			// 		top: 1.5rem solid white;
			// 		bottom: 1.5rem solid white;
			// 	}

			// 	transition: transform 0.4s 0.5s,
			// 		width 0.2s 0.75s;
			// }

			.group-title {
				color: white;

				text-align: center;
			}

			.card-list {
				position: relative;

				transform: translateX(-50%) rotateY(-30deg) scale(0.625);
				transform-origin: right calc((100vh - 15rem) / 2);

				margin-top: 4rem;

				transition: all $transition-slide;

				&::before {
					content: '';

					position: absolute;
					top: 50%;
					left: -3rem;
					z-index: -1;
					transform: translateY(-50%) scaleY(0);
					transform-origin: right 13.5rem;

					// width: 12rem;
					height: calc(100% + 6rem);
					// min-height: 140rem;
					width: 0;

					border: {
						top-left-radius: 3rem;
						bottom-left-radius: 3rem;
						left: 0.75rem solid white;
						top: 0.75rem solid white;
						bottom: 0.75rem solid white;
					}

					transition: transform 0.4s 0.5s,
						width 0.2s 0.75s;
				}
			}

			.card {
				color: $color-change-charcoal;
			}
		}

		.whats-next {
			display: flex;
			width: 100%;
			align-items: center;
			justify-content: space-between;
			margin-top: 14rem;

			h2 {
				transform: translateY(-30%);

				flex: 1 1 auto;
				margin-right: 3rem;
				padding-bottom: 2rem;

				border-bottom: 0.75rem solid white;
				color: white;

				line-height: 1.2em;
			}

			.card-list {
				width: 45rem;

				&::before {
					min-height: 0;
				}
			}

			.card {
				display: flex;
				justify-content: center;
				align-items: center;
				width: 100%;
				height: 18rem;

				// box-shadow: 0 0.5rem 2rem 0.5rem rgba(black, 0.2);
				transition: all $transition, color $transition-slide;

				&:not(:first-child) {
					margin-top: 2rem;
				}

				&:hover, &:focus {
					transform: translateY(-0.25rem);

					box-shadow: 0 0.5rem 1rem rgba(black, 0.3);
				}

				.card-title {
					text-align: center;
				}
			}
		}
	}

	@media screen and (max-width: $bp-tablet) {
		padding: 12rem 0 8rem;
		min-height: 0;

		&[data-level="1"] {
			padding-top: 2rem;

			.breadcrumbs {
				display: none;
			}
		}

		.inner {
			min-height: 0;
		}

		.change-slide {
			.slide-group {
				display: block;
				width: 100vw;
				padding: 0 3.75rem;

				&::before {
					display: none;
				}

				.slide-line {
					display: none;
				}

				.slide-info {
					position: relative;
					left: auto;
					top: auto;

					width: 100%;
					max-width: 60rem;
					margin: 0 auto;
					padding: 0;

					.slide-body {
						margin-top: 1rem;
					}
				}

				.choice-list {
					width: 100%;
					max-width: 60rem;
					margin: 0 auto;
					margin-top: 3rem;
				}
			}
		}

		.change-info {
			.card-group {
				width: 100vw;
				padding: 0 3.75rem;
				margin: 0 auto;

				& > * {
					max-width: 60rem;
					margin-top: 0;
				}

				.slide-line {
					display: none;
				}

				.card-list {
					margin: 0 auto;

					&::before {
						display: none;
					}
				}
			}

			.whats-next {
				display: block;
				margin: 0 auto;
				margin-top: 3.75rem;

				border-bottom: 0;

				h2 {
					transform: none;
					border-bottom: 0;
				}

				.card-list {
					width: auto;
				}

				.card {
					width: 100%;
					max-width: 60rem;
				}
			}
		}
	}
}