.site-footer {
	padding: 3.75rem 0;

	.inner {
		display: flex;
	}

	.footer-section {
		flex: 1 1 auto;
		padding-right: 2rem;

		& > *, .wysiwyg > * {
			&:not(:first-child) {
				margin-top: 2rem;
			}
		}

		.link-group {
			justify-content: flex-start;
			margin: 0;

			.btn-link {
				margin: 0;

				&:not(:first-child) {
					margin-left: 4rem;
				}
			}
		}
	}

	@media screen and (max-width: $bp-tablet) {
		.inner {
			display: block;

			.footer-section {
				width: 100% !important;
				padding-right: 0;

				&:not(:first-child) {
					margin-top: 3.75rem;
				}
			}
		}
	}
}