.section-disclaimer {
	position: absolute;
	z-index: 5;
	bottom: 0;
	left: 0;

	width: 100%;
	height: 100%;

	background: {
		image: url('../img/hatching.png');
		size: 1.5rem 1.5rem;
	}
	color: white;
	visibility: hidden;
	opacity: 0;

	transition: all $transition;

	.-show-disclaimer & {
		visibility: visible;
		opacity: 1;
	}
	
	.disclaimer-content {
		position: fixed;
		bottom: 0;
		left: 0;

		width: 100%;

		background: $color-change-pitch;

		text-align: center;

		.inner {
			padding: 3.75rem;

			& > * {
				max-width: 87.5rem;
				margin: 0 auto;

				&:not(:first-child) {
					margin-top: 2rem;
				}
			}

			.wysiwyg {
				& > * {
					&:not(:first-child) {
						margin-top: 2rem;
					}
				}
			}

			.link {
				text-decoration: underline;
				font-size: 2.5rem;
			}
		}
	}

	@media screen and (max-width: $bp-tablet) {
		background-size: 1rem 1rem;
	}
}