.breadcrumbs {
	position: fixed;
	top: 15rem;
	left: 0;
	z-index: 10;

	filter: drop-shadow(0 0.5rem 0.5rem rgba(black, 0.1));

	display: flex;
	width: 100%;
	height: 6rem;

	font-size: 1.75rem;

	&.-fixed {
		.btn-back {
			background: $color-change-pitch;
		}
	}

	.breadcrumb-list {
		display: flex;
	}

	.list-item {
		position: relative;
		transform: translateX(20%) scale(0.9);

		display: block;
		padding: 0 8rem 0 5rem;
		height: 100%;

		visibility: hidden;
		opacity: 0;
		color: $color-change-charcoal !important;

		transition: all $transition-slide;

		&:hover, &:focus {
			
		}

		@for $i from 1 through 6 {
			@for $j from 1 through $i {
				[data-level='#{$i}'] &:nth-child(#{$j}) {
					transform: none;

					opacity: 1;
					visibility: visible;
				}
			}

			[data-level='#{$i}'] &:nth-child(#{$i}) {
				color: white !important;

				&::before, &::after {
					background: $color-change-charcoal;
				}
			}
		}

		&::before, &::after {
			content: '';

			position: absolute;
			left: 0;

			display: block;
			width: 100%;
			height: 50%;

			background: white;
			box-shadow: -2px 0 0 0 rgba($color-change-tangerine, 0.5) inset;
		}

		&::before {
			top: 0;
			transform: skewX(45deg);
			transform-origin: left bottom;
		}

		&::after {
			bottom: 0;
			transform: skewX(-45deg);
			transform-origin: left top;
		}

		.text {
			position: relative;
			z-index: 1;
		}
	}

	.btn-back {
		display: none;
		width: 100%;
		align-items: center;
		height: 100%;
		padding-left: 3rem;

		filter: drop-shadow(0 0.25rem 0.25rem rgba(black, 0.3));

		svg {
			width: 3rem;
			height: 3rem;

			* {
				fill: white;
			}
		}

		.text {
			margin-left: 2rem;

			@include font-primary-bold;
			font-size: 2.5rem;
		}
	}

	@media screen and (max-width: $bp-tablet) {
		top: 10rem;

		height: 10rem;

		.btn-back {
			display: flex;
		}

		.breadcrumb-list {
			display: none;
		}
	}
}